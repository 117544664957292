import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {catchError, Observable, switchMap,tap, take, throwError} from 'rxjs';
import {AuthService} from "../services";
import {Store} from "@ngxs/store";
import {Login} from "../../store/auth/auth.actions";
import {LoginResponseInterface} from "../interfaces";

@Injectable()
export class RefreshJwtInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private auth: AuthService,
              private store: Store) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // @ts-ignore
    return next.handle(req).pipe(
      catchError(async (error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('login') &&
          error.status === 401
        ) {
          console.log('IT\'S NOT AUTH ROUTE')
          return await this.handle401Error(req, next);
        }
        console.log('IT\'S AUTH ROUTE');
        // return throwError(() => error);
          throw new HttpErrorResponse(error);
      })
    );
  }

  // tslint:disable-next-line:typedef
  private async handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      console.log('START REFRESHING PROCEDURE')

      this.isRefreshing = true;
      // if (this.storageService.isLoggedIn()) {

      const token = this.store.snapshot().auth.token;
      const refreshToken = this.store.snapshot().auth.refreshToken;
      const email = this.store.snapshot().auth.user.email;

      if (token) {
        console.log('USER IS LOGGED IN')

        // @ts-ignore
        return this.auth.refreshToken({refreshToken:refreshToken, email: email}).pipe(
          // @ts-ignore
          tap(async (data) => {
            request.headers.set('x-access-token', token);
            this.isRefreshing = false;
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.status == '403') {}

            return throwError(() => error);
          }),
          tap(async (data: LoginResponseInterface) => {
            console.log('#####', data)
            this.store.dispatch(new Login(data))
          }),
          switchMap((data: any) => {
            const clone = request.clone({ headers: request.headers.set('x-access-token', data.token)})
            return next.handle(clone)
          })
        )
        .subscribe(async (res:any) => {
            const newToken = this.store.snapshot().auth.token;
            const clone = request.clone({ headers: request.headers.set('x-access-token', newToken)})

            return next.handle(clone)
        });
      }
    }

    return next.handle(request);
  }

}
