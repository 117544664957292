import {BrandInterface, LoginResponseInterface} from "../../core/interfaces";

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public data: LoginResponseInterface) {}
}

export class SelectBrand {
  static readonly type = '[Auth] SelectBrand';
  constructor(public brandId: any, public brandName: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
  constructor() {}
}
