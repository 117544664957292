import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';

@Injectable()

export class AuthInterceptor  implements HttpInterceptor {

    constructor(private store: Store) {}
    intercept( req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.store.snapshot().auth.token;

        const authToken = 'Bearer ' + token;

        const reqCopy = req.clone({ headers: req.headers.set('Authorization', authToken)});

        return next.handle(reqCopy);
    }
}
