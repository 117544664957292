import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {RetailQueryModel} from "../../models/retails/retail-query.model";

@Injectable({
  providedIn: 'root'
})
export class RetailService {

    constructor(private http: HttpClient) { }

    getAllActiveFilter(): Observable<any> {
        return this.http.get<any>( environment.url + `/api/RetailReports/loadFiltersList`);
    }

    getRetailReport(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports`, data);
    }

    getLastReport(data: any): Observable<any> {
        // return this.http.get<any>( environment.url + `/api/RetailReports/lastReport`);
        return this.http.get<any>( environment.url + `/api/RetailReports`, {params: {...data}});
    }

    getLastReportDetails(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/OnlineReports/lastReportDetails`, data);
    }

    simulate(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/simulation`, data);
    }

    updateFilter(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/editFilter?filterId=${data.id}`, data);
    }

    getOnlineReportByIdt(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/lastReportDetails`,data);
    }



    getRetailReportByIdt(id: any): Observable<any> {
        return this.http.get<any>( environment.url +  `/api/OnlineReports/reportbyid`,{params:{id}});
    }

    getRetailReportGet(data: any): Observable<any> {
        return this.http.get<any>( environment.url + `/api/RetailReports`, {params: {...data}});
    }


    applyFilter(data: RetailQueryModel): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/Apply`, data);
    }

    deleteReport(id: any): Observable<any> {
        return this.http.delete<any>( environment.url + `/api/RetailReports/${id}`);
    }

    deleteFilter(id: any): Observable<any> {
        return this.http.delete<any>( environment.url + `/api/RetailReports/deleteFilter?filterId=${id}`);
    }

    getRetailColumns(): Observable<any> {
        return this.http.get<any>( environment.url + `/api/RetailBlackList/blackListFilterColumns`);
    }

    setRetailColumns(data): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailBlackList/blackListFilterColumns`, data);
    }

    activateFilter(data): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/activateFilters`, data);
    }

    createFilter(data: any): Observable<any> {
        return this.http.post<any>( environment.url + `/api/RetailReports/createFilter`, data);
    }
}
